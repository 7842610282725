"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// CSS
require('../css/main.css');
// JS
require("./main");
var main_1 = require("./main");
Object.defineProperty(exports, "addMarkerForIP", { enumerable: true, get: function () { return main_1.addMarkerForIP; } });
var main_2 = require("./main");
Object.defineProperty(exports, "whoisClick", { enumerable: true, get: function () { return main_2.whoisClick; } });
