"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IPv4Range = exports.IPv4 = exports.getIPFromXYZ = void 0;
function getIpVal(x, y, z) {
    var ipval = 0;
    var point = { x: 0, y: 0 };
    for (var i = z; i > 0; i--) {
        point.x = x >> (i - 1) & 1;
        point.y = y >> (i - 1) & 1;
        var m = point.x | (point.y << 1);
        var n = point.x | point.y;
        ipval += m * Math.pow(2, n * (32 - ((z - i + 1) << 1)));
    }
    return ipval;
}
function getIPFromXYZ(x, y, z) {
    var maxCoord = Math.pow(2, z);
    // out of bounds ?
    if (x >= maxCoord || y >= maxCoord) {
        return null;
    }
    var ipval = 0;
    var point = { x: 0, y: 0 };
    for (var i = z; i > 0; i--) {
        point.x = x >> (i - 1) & 1;
        point.y = y >> (i - 1) & 1;
        var m = point.x | (point.y << 1);
        var n = point.x | point.y;
        ipval += m * Math.pow(2, n * (32 - ((z - i + 1) << 1)));
    }
    var strip = (ipval >> 24 & 255) + "." + (ipval >> 16 & 255) + "." + (ipval >> 8 & 255) + "." + (ipval & 255);
    var myIP = new IPv4(ipval);
    return myIP;
}
exports.getIPFromXYZ = getIPFromXYZ;
var IPv4 = /** @class */ (function () {
    function IPv4(value) {
        var _this = this;
        if (value === void 0) { value = 0; }
        this.toString = function () {
            return _this.pString;
        };
        this.pVal = value;
    }
    Object.defineProperty(IPv4.prototype, "pVal", {
        get: function () {
            return this.ipval;
        },
        set: function (val) {
            this.ipval = val;
            this.point = null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IPv4.prototype, "pPoint", {
        get: function () {
            if (!this.point) {
                this.point = IPv4.getPointFromVal(this.ipval);
            }
            return this.point;
        },
        set: function (p) {
            this.point = p;
            this.ipval = getIpVal(p.x, p.y, 16);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IPv4.prototype, "pString", {
        get: function () {
            return [this.ipval >> 24 & 0xff, this.ipval >> 16 & 0xff, this.ipval >> 8 & 0xff, this.ipval & 0xff].join(".");
        },
        set: function (s) {
            var arr = s.split(".");
            this.pVal = IPv4.getIntValue(Number(arr[0]), Number(arr[1]), Number(arr[2]), Number(arr[3]));
        },
        enumerable: false,
        configurable: true
    });
    IPv4.prototype.getLastIPMask = function (mask) {
        /* number of lines
        let height: number = 1 << ((32 - mask) >> 1);
        let width: number = height;
        const ipEnd: IPv4 = new IPv4();
        ipEnd.pPoint = { x: this.pPoint.x + width - 1, y: this.pPoint.y + height - 1 };
        const ipTest: IPv4 =
        console.log("test:"+ipTest.pVal+", "+ipEnd.pVal);
        */
        return new IPv4(this.pVal + Math.pow(2, (32 - mask)) - 1);
    };
    IPv4.getPointFromVal = function (ipval) {
        var twobit;
        var x = 0;
        var y = 0;
        for (var i = 15; i >= 0; i--) {
            twobit = (ipval >> i * 2) & 3;
            x += (twobit & 1) * Math.pow(2, i);
            y += ((twobit >> 1) & 1) * Math.pow(2, i);
        }
        return { y: y, x: x };
    };
    IPv4.newIPv4FromString = function (s) {
        var myip = new IPv4();
        myip.pString = s;
        return myip;
    };
    IPv4.newIPv4FromPoint = function (p) {
        var myip = new IPv4();
        myip.pPoint = p;
        return myip;
    };
    IPv4.getIntValue = function (ip_b3, ip_b2, ip_b1, ip_b0) {
        return ip_b3 * IPv4.pow_256_3 + ip_b2 * IPv4.pow_256_2 + ip_b1 * 256 + ip_b0;
    };
    IPv4.newIPv4FromRange = function (s) {
        var ipReg = "(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";
        var fullReg = "^(" + ipReg + "\." + ipReg + "\." + ipReg + "\." + ipReg + ")/([0-9]{1,2})$";
        var regExpExecArray = (new RegExp(fullReg)).exec(s);
        if (regExpExecArray) {
            var r = regExpExecArray[6];
            var ipstart = IPv4.newIPv4FromString(regExpExecArray[1]);
            var ipend = new IPv4(ipstart.ipval + (Math.pow(2, parseInt(r)) - 1));
            return [ipstart, ipend];
        }
        return [];
    };
    IPv4.prototype.deduceRange = function (r) {
        var lowMask = Math.pow(2, 32 - r) - 1;
        var highMask = 0xffffffff - lowMask;
        var range = new IPv4Range(this.pVal & highMask);
        range.pIpEnd = new IPv4(this.pVal | lowMask);
        return range;
    };
    IPv4.pow_2_16 = Math.pow(2, 16);
    IPv4.pow_256_3 = Math.pow(256, 3);
    IPv4.pow_256_2 = Math.pow(256, 2);
    return IPv4;
}());
exports.IPv4 = IPv4;
var IPv4Range = /** @class */ (function () {
    function IPv4Range(value, range) {
        var _this = this;
        if (value === void 0) { value = 0; }
        if (range === void 0) { range = 32; }
        this.toString = function () {
            return _this.ipStart.toString() + '/' + _this.range;
        };
        this.ipStart = new IPv4(value);
        this.range = range;
    }
    Object.defineProperty(IPv4Range.prototype, "pIpStart", {
        get: function () {
            return this.ipStart;
        },
        set: function (s) {
            this.ipStart = s;
            this.ipEnd = null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IPv4Range.prototype, "pRange", {
        get: function () {
            return this.range;
        },
        set: function (r) {
            this.range = r;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IPv4Range.prototype, "pIpEnd", {
        get: function () {
            if (!this.ipEnd) {
                this.ipEnd = new IPv4(this.ipStart.pVal + (Math.pow(2, 32 - this.range) - 1));
            }
            return this.ipEnd;
        },
        set: function (e) {
            var delta = e.pVal - this.ipStart.pVal;
            this.ipEnd = e;
            this.range = 32 - Math.log2(delta + 1);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IPv4Range.prototype, "howMany", {
        get: function () {
            return Math.pow(2, 32 - this.range) - 1;
        },
        enumerable: false,
        configurable: true
    });
    IPv4Range.newIPv4RangeFromString = function (s) {
        var ipReg = "(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";
        var fullReg = "^(" + ipReg + "\." + ipReg + "\." + ipReg + "\." + ipReg + ")/([0-9]{1,2})$";
        var regExpExecArray = (new RegExp(fullReg)).exec(s);
        if (regExpExecArray) {
            var r = regExpExecArray[6];
            var res = new IPv4Range();
            res.ipStart = IPv4.newIPv4FromString(regExpExecArray[1]);
            res.pRange = parseInt(r);
            return res;
        }
        return null;
    };
    return IPv4Range;
}());
exports.IPv4Range = IPv4Range;
exports.default = IPv4;
