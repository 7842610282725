"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.whoisClick = exports.addMarkerForIP = void 0;
var index_1 = require("../../ipv4/index");
var L = require("leaflet");
var leaflet_1 = require("leaflet");
var ipv4_1 = require("../../ipv4/ipv4");
var mymap = L.map("mapid").fitWorld();
var myip = index_1.IPv4.newIPv4FromString(document.getElementById("ip").innerText);
var initZoomLevel = parseInt(document.getElementById("zoomlevel").innerText) || 2;
L.tileLayer("/tiles/{z}/{x}/{y}", {
    maxZoom: 16,
    attribution: "",
    id: "mapbox.streets",
    noWrap: true,
    bounds: [
        [-90, -180],
        [90, 180]
    ]
}).addTo(mymap);
function getIpVal(x, y, z) {
    var ipval = 0;
    var point = { x: 0, y: 0 };
    for (var i = z; i > 0; i--) {
        point.x = x >> (i - 1) & 1;
        point.y = y >> (i - 1) & 1;
        var m = point.x | (point.y << 1);
        var n = point.x | point.y;
        ipval += m * Math.pow(2, n * (32 - ((z - i + 1) << 1)));
    }
    return ipval;
}
function getAbsolutePoint(map, latlng) {
    var p = map.project(latlng, map.getZoom());
    return p.multiplyBy(Math.pow(2, map.getMaxZoom() - map.getZoom() - 8)).floor();
}
function getLatLng(map, point, offset) {
    if (offset === void 0) { offset = 0; }
    var ret;
    try {
        var p = new L.Point(point.x + offset, point.y + offset);
        var mypoint = p.divideBy(Math.pow(2, map.getMaxZoom() - map.getZoom() - 8));
        ret = mymap.unproject(mypoint, map.getZoom());
    }
    catch (e) {
        throw e;
    }
    return ret;
}
function castLPoint(p) {
    var point = new L.Point(p.x, p.y);
    return point;
}
var popup = L.popup();
var marker;
var selection;
function onMapClick(e) {
    var mypoint = getAbsolutePoint(mymap, e.latlng);
    var myip = index_1.IPv4.newIPv4FromPoint(mypoint);
    document.getElementById("show").className = "off";
    marker && mymap.removeLayer(marker);
    selection && mymap.removeLayer(selection);
    if (e.target._zoom <= 4) {
        var myrange = myip.deduceRange(8);
        var latlngf = getLatLng(mymap, castLPoint(myrange.pIpStart.pPoint), 0.5);
        var latlngt = getLatLng(mymap, castLPoint(myrange.pIpEnd.pPoint), 0.5);
        // define rectangle geographical bounds
        var bounds = new leaflet_1.LatLngBounds(latlngf, latlngt);
        // create an orange rectangle
        selection = L.rectangle(bounds, { color: "#ff7800", weight: 1 }).addTo(mymap);
        // zoom the map to the rectangle bounds
        //mymap.fitBounds(bounds);
        queryJson("/info/" + mypoint.x + "/" + mypoint.y + "/" + e.target._zoom, function (r) {
            var ir = ipv4_1.IPv4Range.newIPv4RangeFromString(r.ip);
            showModalRange(ir);
            updateQueryParamIp(ir.toString());
        });
    }
    else {
        marker = L.marker(e.latlng).addTo(mymap);
        /*popup
            .setLatLng(e.latlng)
            .setContent(myip.toString())
            .openOn(mymap);*/
        showModal(myip);
        updateQueryParamIp(myip.toString());
    }
}
function getQueryParams() {
    // Get current parameters from the address bar
    return new URLSearchParams(window.location.pathname.substring(2));
}
function setQueryParams(queryParams) {
    // Replace current parameters with the new ones
    history.replaceState(null, null, "@" + queryParams.toString());
}
function updateQueryParamIp(ip) {
    // Get current parameters from the address bar
    var queryParams = getQueryParams();
    // Update the parameter ip
    queryParams.set("ip", ip);
    // Replace current parameters with the new ones
    setQueryParams(queryParams);
}
function query(ip, callback) {
    var request = new XMLHttpRequest();
    request.open('GET', '/whois/' + ip.replace('/', '_-_'), true);
    request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
            // Success!
            var resp = request.responseText;
            updateQueryParamIp(ip);
            callback(resp);
        }
        else {
            // We reached our target server, but it returned an error
            console.log('We reached our target server, but it returned an error');
        }
    };
    request.onerror = function () {
        // There was a connection error of some sort
        console.log('There was a connection error of some sort');
    };
    request.send();
}
function queryNS(ns, callback) {
    var request = new XMLHttpRequest();
    request.open('GET', '/nslookup/' + ns, true);
    request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
            // Success!
            var resp = request.responseText;
            callback(resp);
        }
        else {
            // We reached our target server, but it returned an error
            console.log('We reached our target server, but it returned an error');
        }
    };
    request.onerror = function () {
        // There was a connection error of some sort
        console.log('There was a connection error of some sort');
    };
    request.send();
}
function queryJson(url, callback) {
    var request = new XMLHttpRequest();
    request.responseType = 'json';
    request.open('GET', url, true);
    request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
            // Success!
            var resp = request.response;
            callback(resp);
        }
        else {
            // We reached our target server, but it returned an error
            console.log('We reached our target server, but it returned an error');
        }
    };
    request.onerror = function () {
        // There was a connection error of some sort
        console.log('There was a connection error of some sort');
    };
    request.send();
}
mymap.on("click", onMapClick);
mymap.on('zoomend', function () {
    // Get current parameters from the address bar
    var queryParams = getQueryParams();
    // Update the parameter ip
    queryParams.set("zoom", mymap.getZoom().toString());
    // Replace current parameters with the new ones
    setQueryParams(queryParams);
});
window.onload = function () {
    if (myip.toString() !== "0.0.0.0") {
        var latlng = getLatLng(mymap, castLPoint(myip.pPoint), 0.5);
        mymap.setView(latlng, initZoomLevel);
        marker = L.marker(latlng).addTo(mymap);
        marker.bindPopup("You are here.<br/>" + myip).openPopup();
    }
};
function showModal(search) {
    document.getElementById("show").className = "mini";
    document.querySelector("#show ip").textContent = search.toString();
    document.querySelector("#show .range").textContent = '';
    document.querySelector("#show .whois").textContent = "";
}
function showModalRange(search) {
    document.getElementById("show").className = "mini";
    document.querySelector("#show ip").textContent = search.toString();
    document.querySelector("#show .range").textContent = search.pIpStart + " to " + search.pIpEnd + " (" + search.howMany + " addresses)";
    document.querySelector("#show .whois").textContent = "";
}
function addMarkerForIP(searchString) {
    var ipReg = "(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";
    var fullReg = "^" + ipReg + "\." + ipReg + "\." + ipReg + "\." + ipReg + "$";
    var nsReg = "^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$";
    if ((new RegExp(fullReg)).test(searchString)) {
        var ipString = searchString;
        var ip = index_1.IPv4.newIPv4FromString(ipString);
        var latlng = getLatLng(mymap, castLPoint(ip.pPoint), 0.5);
        //L.marker(latlng).addTo(mymap);
        mymap.panTo(latlng);
        mymap.setView(latlng, 16);
        marker && mymap.removeLayer(marker);
        marker = L.marker(latlng).addTo(mymap);
        /*popup
            .setLatLng(latlng)
            .setContent(ip.toString())
            .openOn(mymap);*/
        showModal(ip);
    }
    else if ((new RegExp(nsReg)).test(searchString)) {
        var ns = searchString;
        queryNS(ns, function (ipString) {
            var ip = index_1.IPv4.newIPv4FromString(ipString);
            var latlng = getLatLng(mymap, castLPoint(ip.pPoint), 0.5);
            mymap.panTo(latlng);
            mymap.setView(latlng, 16);
            marker && mymap.removeLayer(marker);
            marker = L.marker(latlng).addTo(mymap);
            /*popup
                .setLatLng(latlng)
                .setContent(ip.toString())
                .openOn(mymap);*/
            showModal(ip);
        });
    }
    else {
    }
}
exports.addMarkerForIP = addMarkerForIP;
function whoisClick() {
    var whois = document.querySelector("#show .whois").textContent;
    if (whois !== "") {
        document.getElementById("show").className = "mini";
        document.querySelector("#show .whois").textContent = "";
    }
    else {
        document.getElementById("show").className = "full";
        var ip = document.querySelector("#show ip").textContent;
        query(ip, function (whois) {
            document.querySelector("#show .whois").textContent = whois;
        });
    }
}
exports.whoisClick = whoisClick;
